export const apiPath = process.env.NODE_ENV === 'production'
    ? '/api'
    : 'http://localhost:55133';

export const googleMapsConfig = {
    'fullscreenControl': false,
    'mapTypeControl': false,
    'streetViewControl': false,
    'zoom': 16,
    'center': {
        'lat': 3.1482766,
        'lng': 101.6941732
    }
};

export const datePickerConfig = {
    'autoHide': true,
    'format': 'dd/mm/yyyy'
};

export const statusRange = [
    {
        'text': 'Active',
        'value': '1'
    },
    {
        'text': 'Disabled',
        'value': '0'
    }
];

export const notificationDelayRange = [
    {
        'text': 'Instant',
        'value': '0'
    },
    {
        'text': '30 secs',
        'value': '30'
    },
    {
        'text': '1 min',
        'value': '60'
    },
    {
        'text': '5 mins',
        'value': '300'
    },
    {
        'text': '10 mins',
        'value': '600'
    },
    {
        'text': '30 mins',
        'value': '1800'
    },
    {
        'text': '1 hour',
        'value': '3600'
    },
    {
        'text': '2 hour',
        'value': '7200'
    },
    {
        'text': '6 hour',
        'value': '21600'
    },
    {
        'text': '12 hour',
        'value': '43200'
    },
    {
        'text': '1 day',
        'value': '86400'
    },
    {
        'text': '1 week',
        'value': '604800'
    },
    {
        'text': '1 month',
        'value': '2592000'
    }
];

// in meters
export const placesSearchRange = [
    {
        'text': '500m',
        'value': '500'
    },
    {
        'text': '1km',
        'value': '100'
    },
    {
        'text': '2km',
        'value': '2000'
    },
    {
        'text': '5km',
        'value': '5000'
    },
    {
        'text': '10km',
        'value': '10000'
    },
    {
        'text': '20km',
        'value': '20000'
    },
    {
        'text': '50km',
        'value': '50000'
    }
];

export const placeTypes = [
    {
        'text': 'Workshop',
        'value': 'Workshop'
    },
    {
        'text': 'Medical',
        'value': 'Medical'
    }
];

export const feedbackSubjects = [
    {
        'text': 'Service and Quality',
        'value': 'Service and Quality'
    },
    {
        'text': 'System Look and Feel',
        'value': 'System Look and Feel'
    },
    {
        'text': 'Technical Issue',
        'value': 'Technical Issue'
    },
    {
        'text': 'Service Level',
        'value': 'Service Level'
    },
    {
        'text': 'Suggestion',
        'value': 'Suggestion'
    }
];

export const genders = [
    {
        'text': 'Male',
        'value': 1
    },
    {
        'text': 'Female',
        'value': 2
    }
];

export const userDisabledStatus = [
    {
        'text': 'Active',
        'value': false
    },
    {
        'text': 'Disabled',
        'value': true
    }
];
