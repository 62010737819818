import Layout from '@/layout';
import EmptyLayout from '@/layout/empty';

export default [
    {
        'path': '/',
        'name': 'defaultRouter',
        'component': Layout,
        'children': [
            {
                'path': 'account',
                'name': 'accountRouter',
                'component': EmptyLayout,
                'children': [
                    {
                        'path': 'login',
                        'name': 'login',
                        'meta': {
                            'isHidden': true,
                            'noDefaultContainer': true
                        },
                        'component': (resolve) => {
                            require(['@/views/account/login'], resolve);
                        }
                    },
                    {
                        'path': 'register',
                        'name': 'register',
                        'meta': {
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/account/register'], resolve);
                        }
                    },
                    {
                        'path': 'company/register',
                        'name': 'company-register',
                        'meta': {
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/account/company/register'], resolve);
                        }
                    },
                    {
                        'path': 'forgot-password',
                        'name': 'forgot-password',
                        'meta': {
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/account/forgotPassword'], resolve);
                        }
                    },
                    {
                        'path': 'activate',
                        'name': 'activate',
                        'meta': {
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/account/activate'], resolve);
                        }
                    },
                    {
                        'path': 'passwordreset',
                        'name': 'passwordreset',
                        'meta': {
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/account/passwordreset'], resolve);
                        }
                    },
                    {
                        'path': 'public-reminder',
                        'name': 'public-reminder',
                        'meta': {
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/account/publicReminder'], resolve);
                        }
                    },
                    {
                        'path': 'change-password',
                        'name': 'change-password',
                        'meta': {
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/account/changePassword'], resolve);
                        }
                    },
                    {
                        'path': 'change-email',
                        'name': 'change-email',
                        'meta': {
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/account/changeEmail'], resolve);
                        }
                    },
                    {
                        'path': 'verifyemailchange',
                        'name': 'verifyemailchange',
                        'meta': {
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/account/emailreset'], resolve);
                        }
                    },
                    {
                        'path': 'security-phrase',
                        'name': 'security-phrase',
                        'meta': {
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/account/securityPhrase'], resolve);
                        }
                    },
                    {
                        'path': 'set-password',
                        'name': 'set-password',
                        'meta': {
                            'isHidden': true,
                            'noDefaultContainer': true
                        },
                        'component': (resolve) => {
                            require(['@/views/account/setPassword'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'dashboard',
                'name': 'dashboard',
                'meta': {
                    'title': 'Dashboard',
                    'icon': 'fa-pie-chart'
                },
                'component': (resolve) => {
                    require(['@/views/dashboard/index.vue'], resolve);
                }
            },
            // {
            //     'path': 'dashboard',
            //     'name': 'dashboard',
            //     'meta': {
            //         'title': 'Dashboard',
            //         'icon': 'fa-pie-chart',
            //         'isAdmin': true
            //     },
            //     'component': (resolve) => {
            //         require(['@/views/dashboard/index.vue'], resolve);
            //     }
            // },
            {
                'path': 'profile',
                'component': EmptyLayout,
                'meta': {
                    'group': false
                },
                'children': [
                    {
                        'path': 'user',
                        'name': 'profile-user',
                        'meta': {
                            'title': 'My Profile',
                            'icon': 'fa-user'
                        },
                        'component': (resolve) => {
                            require(['@/views/profile/user.vue'], resolve);
                        }
                    },
                    {
                        'path': 'admin',
                        'name': 'profile-admin',
                        'meta': {
                            'title': 'My Profile',
                            'icon': 'fa-user',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/profile/admin.vue'], resolve);
                        }
                    },
                    {
                        'path': 'complete-my-profile',
                        'name': 'complete-user-profile',
                        'meta': {
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/profile/completeProfile.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'family',
                'component': EmptyLayout,
                'meta': {
                    'group': false,
                    'isNotForCompany': true
                },
                'children': [
                    {
                        'path': '',
                        'name': 'family-list',
                        'meta': {
                            'title': 'My Family',
                            'icon': 'fa-users',
                            'titleForCompany': 'Subsidiaries'
                        },
                        'component': (resolve) => {
                            require(['@/views/family/list.vue'], resolve);
                        }
                    },
                    {
                        'path': 'view/:id',
                        'name': 'family-detail',
                        'meta': {
                            'title': 'Family Member',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/family/detail.vue'], resolve);
                        }
                    },
                    {
                        'path': 'add',
                        'name': 'family-add',
                        'meta': {
                            'title': 'Add Family Member',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/family/add.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'users',
                'component': EmptyLayout,
                'meta': {
                    'group': true,
                    'title': 'Users',
                    'icon': 'fa-users'
                },
                'children': [
                    {
                        'path': '',
                        'redirect': { 'name': 'active-users' },
                        'meta': {
                            'isHidden': true
                        }
                    },
                    {
                        'path': 'active',
                        'name': 'active-users',
                        'meta': {
                            'title': 'Active Users',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/user/list.vue'], resolve);
                        }
                    },
                    {
                        'path': 'barred',
                        'name': 'barred-users',
                        'meta': {
                            'title': 'Barred Users',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/user/list.vue'], resolve);
                        }
                    },
                    {
                        'path': 'detail/:id',
                        'name': 'user-detail',
                        'meta': {
                            'title': 'User Details',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/user/detail.vue'], resolve);
                        }
                    },
                    {
                        'path': 'new',
                        'name': 'new-user',
                        'meta': {
                            'title': 'New User',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/user/new.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'cars-list',
                'component': EmptyLayout,
                'meta': {
                    'group': true,
                    'title': 'Cars',
                    'icon': 'fa-car'
                },
                'children': [
                    {
                        'path': '',
                        'redirect': { 'name': 'customer-cars' },
                        'meta': {
                            'isHidden': true
                        }
                    },
                    {
                        'path': 'all',
                        'name': 'customer-cars',
                        'meta': {
                            'title': 'Customer Cars',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/car/admin/list.vue'], resolve);
                        }
                    },
                    {
                        'path': 'telematic-cars',
                        'name': 'telematic-cars',
                        'meta': {
                            'title': 'Telematics',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/car/admin/list-telematics.vue'], resolve);
                        }
                    },
                    {
                        'path': 'view/:id',
                        'name': 'customer-car-detail',
                        'meta': {
                            'title': 'Car Details',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/car/admin/detail.vue'], resolve);
                        }
                    }
                ]
            },
            // {
            //     'path': 'claim',
            //     'component': EmptyLayout,
            //     'meta': {
            //         'group': false
            //     },
            //     'children': [
            //         {
            //             'path': '',
            //             'name': 'claim-list',
            //             'meta': {
            //                 'title': 'Claims',
            //                 'icon': 'fa-dollar'
            //             },
            //             'component': (resolve) => {
            //                 require(['@/views/claim/index.vue'], resolve);
            //             }
            //         },
            //         {
            //             'path': 'view/',
            //             'redirect': { 'name': 'claim-list' },
            //             'meta': {
            //                 'isHidden': true
            //             }
            //         },
            //         {
            //             'path': 'view/:id',
            //             'name': 'claim-detail',
            //             'meta': {
            //                 'title': 'View Claim',
            //                 'isHidden': true
            //             },
            //             'component': (resolve) => {
            //                 require(['@/views/claim/detail.vue'], resolve);
            //             }
            //         },
            //         {
            //             'path': 'new/',
            //             'name': 'new-claim',
            //             'meta': {
            //                 'title': 'New Claim',
            //                 'isHidden': true
            //             },
            //             'component': (resolve) => {
            //                 require(['@/views/claim/new.vue'], resolve);
            //             }
            //         }
            //     ]
            // },
            // {
            //     'path': 'claim-review',
            //     'component': EmptyLayout,
            //     'meta': {
            //         'group': true,
            //         'title': 'Claim List',
            //         'icon': 'fa-dollar'
            //     },
            //     'children': [
            //         {
            //             'path': 'pending-list',
            //             'name': 'pending-claims',
            //             'meta': {
            //                 'title': 'Pending Claims',
            //                 'isAdmin': true
            //             },
            //             'component': (resolve) => {
            //                 require(['@/views/claim/admin/pending.vue'], resolve);
            //             }
            //         },
            //         {
            //             'path': 'closed-list',
            //             'name': 'closed-claims',
            //             'meta': {
            //                 'title': 'Closed Claims',
            //                 'isAdmin': true
            //             },
            //             'component': (resolve) => {
            //                 require(['@/views/claim/admin/closed.vue'], resolve);
            //             }
            //         },
            //         {
            //             'path': 'reviewed-list',
            //             'name': 'reviewed-claims',
            //             'meta': {
            //                 'title': 'Claims Rating',
            //                 'isAdmin': true
            //             },
            //             'component': (resolve) => {
            //                 require(['@/views/claim/admin/reviewed.vue'], resolve);
            //             }
            //         },
            //         {
            //             'path': 'admin/view/:id',
            //             'name': 'claim-admin-detail',
            //             'meta': {
            //                 'title': 'View Claim',
            //                 'isHidden': true
            //             },
            //             'component': (resolve) => {
            //                 require(['@/views/claim/admin/detail.vue'], resolve);
            //             }
            //         }
            //     ]
            // },
            {
                'path': 'cars',
                'component': EmptyLayout,
                'meta': {
                    'group': false
                },
                'children': [
                    {
                        'path': '',
                        'name': 'cars',
                        'meta': {
                            'title': 'My Cars / Renew Telematics',
                            'icon': 'fa-car'
                        },
                        'component': (resolve) => {
                            require(['@/views/car/list.vue'], resolve);
                        }
                    },
                    {
                        'path': 'view/:id',
                        'name': 'car-detail',
                        'meta': {
                            'title': 'Car Details',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/car/detail.vue'], resolve);
                        }
                    },
                    {
                        'path': 'add',
                        'name': 'car-add',
                        'meta': {
                            'title': 'Add Car',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/car/add.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'quotations',
                'component': EmptyLayout,
                'meta': {
                    'group': false
                },
                'children': [
                    {
                        'path': '',
                        'name': 'quotations',
                        'meta': {
                            'title': 'Motor Quotations',
                            'icon': 'fa-file-text'
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/list.vue'], resolve);
                        }
                    },
                    {
                        'path': 'view/:id',
                        'name': 'quotation-detail',
                        'meta': {
                            'title': 'Quotation Details',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/detail.vue'], resolve);
                        }
                    },
                    {
                        'path': 'request/:id',
                        'name': 'quotation-request',
                        'meta': {
                            'title': 'Request Quotation',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/request.vue'], resolve);
                        }
                    },
                    {
                        'path': 'pay/:id',
                        'name': 'quotation-pay',
                        'meta': {
                            'title': 'Pay for Quotation',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/pay.vue'], resolve);
                        }
                    },
                    {
                        'path': 'telematics-request',
                        'name': 'telematics-request',
                        'meta': {
                            'title': 'Purchase Telematics',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/telematics.vue'], resolve);
                        }
                    },
                    {
                        'path': 'request-single',
                        'name': 'quotation-request-single',
                        'meta': {
                            'title': 'Request Quotations',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/request-single-page.vue'], resolve);
                        }
                    },
                    {
                        'path': 'pay-public/:id',
                        'name': 'quotation-pay-public',
                        'meta': {
                            'title': 'Pay for Quotation',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/pay-public.vue'], resolve);
                        }
                    },
                    {
                        'path': 'pay-done',
                        'name': 'quotation-pay-done',
                        'meta': {
                            'title': 'Payment Done',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/pay-done.vue'], resolve);
                        }
                    },
                    {
                        'path': 'pay-failed',
                        'name': 'quotation-pay-failed',
                        'meta': {
                            'title': 'Payment Failed',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/pay-failed.vue'], resolve);
                        }
                    },
                    {
                        'path': 'insurance-related-services',
                        'name': 'insurance-related-services',
                        'meta': {
                            'title': 'Insurance Related Services',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/insurance-related-services.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'customer-quotations',
                'component': EmptyLayout,
                'meta': {
                    'group': true,
                    'title': 'Quotation List',
                    'icon': 'fa-file-text'
                },
                'children': [
                    {
                        'path': '',
                        'redirect': { 'name': 'new-quotations' },
                        'meta': {
                            'isHidden': true
                        }
                    },
                    {
                        'path': 'new',
                        'name': 'new-quotations',
                        'meta': {
                            'title': 'New Quotations',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/admin/new.vue'], resolve);
                        }
                    },
                    {
                        'path': 'completed',
                        'name': 'completed-quotations',
                        'meta': {
                            'title': 'Completed List',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/admin/completed.vue'], resolve);
                        }
                    },
                    {
                        'path': 'paid',
                        'name': 'paid-quotations',
                        'meta': {
                            'title': 'Paid Quotations',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/admin/paid-list.vue'], resolve);
                        }
                    },
                    {
                        'path': 'policy-issued',
                        'name': 'policy-issued-quotations',
                        'meta': {
                            'title': 'Policy Issued',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/admin/policy-issued.vue'], resolve);
                        }
                    },
                    {
                        'path': 'expecting-roadtax',
                        'name': 'expecting-roadtax-quotations',
                        'meta': {
                            'title': 'Expecting Roadtax',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/admin/expecting-roadtax.vue'], resolve);
                        }
                    },
                    {
                        'path': 'cancelled',
                        'name': 'cancelled-quotations',
                        'meta': {
                            'title': 'Cancelled Payments',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/admin/cancelled-list.vue'], resolve);
                        }
                    },
                    {
                        'path': 'expired',
                        'name': 'expired-quotations',
                        'meta': {
                            'title': 'Expired',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/admin/expired.vue'], resolve);
                        }
                    },
                    {
                        'path': 'removed',
                        'name': 'removed-quotations',
                        'meta': {
                            'title': 'Removed',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/admin/removed.vue'], resolve);
                        }
                    },
                    {
                        'path': 'detail/:id',
                        'name': 'customer-quotation-detail',
                        'meta': {
                            'title': 'Quotation Details',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/admin/detail.vue'], resolve);
                        }
                    },
                    {
                        'path': 'edit/:id',
                        'name': 'customer-quotation-edit',
                        'meta': {
                            'title': 'Edit Quotation',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/admin/edit.vue'], resolve);
                        }
                    },
                    {
                        'path': 'new',
                        'name': 'referred-risk',
                        'meta': {
                            'title': 'Referred Risk',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/admin/referred-risk.vue'], resolve);
                        }
                    },
                    {
                        'path': 'sub-standard',
                        'name': 'sub-standard',
                        'meta': {
                            'title': 'Sub Standard',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/admin/sub-standard-list.vue'], resolve);
                        }
                    },
                    {
                        'path': 'sub-standard-detail/:id',
                        'name': 'sub-standard-detail',
                        'meta': {
                            'title': 'Sub Standard',
                            'isAdmin': true,
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/quotation/admin/sub-standard-detail.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'health-insurance',
                'component': EmptyLayout,
                'meta': {
                    'group': false
                },
                'children': [
                    {
                        'path': 'list',
                        'name': 'health-insurance-list',
                        'meta': {
                            'title': 'Health Insurance',
                            'icon': 'fa-id-card-o',
                            'isNotForCompany': true
                            // 'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/health-insurance/index'], resolve);
                        }
                    },
                    {
                        'path': 'buy',
                        'name': 'buy-health-insurance',
                        'meta': {
                            'title': 'Buy Health Insurance',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/health-insurance/buy'], resolve);
                        }
                    },
                    {
                        'path': 'view/:id',
                        'name': 'view-health-insurance-quotation',
                        'meta': {
                            'title': 'View Quotation',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/health-insurance/view'], resolve);
                        }
                    },
                    {
                        'path': 'pay/:id/selected/:quotationItem',
                        'name': 'pay-health-insurance-quotation',
                        'meta': {
                            'title': 'Pay Quotation',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/health-insurance/pay'], resolve);
                        }
                    },
                    {
                        'path': 'key-info/:id',
                        'name': 'health-insurance-quotation-key-info',
                        'meta': {
                            'title': 'Key Info',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/health-insurance/key-info'], resolve);
                        }
                    },
                    {
                        'path': 'premium-checking',
                        'name': 'health-insurance-premium-checking',
                        'meta': {
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/health-insurance/premium-checking'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'group-health-insurance/admin',
                'component': EmptyLayout,
                'meta': {
                    'title': 'Group Insurance',
                    'icon': 'fa-id-card',
                    'group': true
                },
                'children': [
                    {
                        'path': 'quotations',
                        'name': 'group-health-insurance-quotation-list-admin',
                        'meta': {
                            'title': 'Quotations',
                            'isAdmin': true
                            // 'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/group-health-insurance/admin/index'], resolve);
                        }
                    },
                    {
                        'path': 'policies',
                        'name': 'group-health-insurance-list-admin',
                        'meta': {
                            'title': 'Policies',
                            'isAdmin': true
                            // 'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/group-health-insurance/admin/policies'], resolve);
                        }
                    },
                    {
                        'path': 'view/quotations/:id',
                        'name': 'view-group-health-insurance-quotation-admin',
                        'meta': {
                            'title': 'View Quotation',
                            'isAdmin': true,
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/group-health-insurance/admin/view'], resolve);
                        }
                    },
                    {
                        'path': 'view/:id',
                        'name': 'view-group-health-insurance-admin',
                        'meta': {
                            'title': 'View Policy',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/group-health-insurance/policy'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'group-health-insurance',
                'component': EmptyLayout,
                'meta': {
                    'group': true,
                    'title': 'Group Insurance',
                    'icon': 'fa-id-card'
                },
                'children': [
                    {
                        'path': 'quotations',
                        'name': 'group-health-insurance-quotation-list',
                        'meta': {
                            'title': 'Quotations'
                            // 'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/group-health-insurance/index'], resolve);
                        }
                    },
                    {
                        'path': 'policies',
                        'name': 'group-health-insurance-list',
                        'meta': {
                            'title': 'Policies'
                            // 'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/group-health-insurance/policies'], resolve);
                        }
                    },
                    {
                        'path': 'buy',
                        'name': 'buy-group-health-insurance',
                        'meta': {
                            'title': 'Buy Group Insurance',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/group-health-insurance/buy'], resolve);
                        }
                    },
                    {
                        'path': 'view/quotation/:id',
                        'name': 'view-group-health-insurance-quotation',
                        'meta': {
                            'title': 'View Quotation',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/group-health-insurance/view'], resolve);
                        }
                    },
                    {
                        'path': 'view/:id',
                        'name': 'view-group-health-insurance',
                        'meta': {
                            'title': 'View Policy',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/group-health-insurance/policy'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'general-insurance/admin',
                'component': EmptyLayout,
                'meta': {
                    'title': 'General Insurance',
                    'icon': 'fa-id-card',
                    'group': true
                },
                'children': [
                    {
                        'path': 'quotations',
                        'name': 'general-insurance-quotation-list-admin',
                        'meta': {
                            'title': 'Quotations',
                            'isAdmin': true
                            // 'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/general-insurance/admin/index'], resolve);
                        }
                    },
                    {
                        'path': 'policies',
                        'name': 'general-insurance-list-admin',
                        'meta': {
                            'title': 'Policies',
                            'isAdmin': true
                            // 'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/general-insurance/admin/policies'], resolve);
                        }
                    },
                    {
                        'path': 'view/quotations/:id',
                        'name': 'view-general-insurance-quotation-admin',
                        'meta': {
                            'title': 'View Quotation',
                            'isAdmin': true,
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/general-insurance/admin/view'], resolve);
                        }
                    },
                    {
                        'path': 'view/:id',
                        'name': 'view-general-insurance-admin',
                        'meta': {
                            'title': 'View Policy',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/general-insurance/policy'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'general-insurance',
                'component': EmptyLayout,
                'meta': {
                    'group': true,
                    'title': 'General Insurance',
                    'icon': 'fa-id-card'
                },
                'children': [
                    {
                        'path': 'quotations',
                        'name': 'general-insurance-quotation-list',
                        'meta': {
                            'title': 'Quotations'
                            // 'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/general-insurance/index'], resolve);
                        }
                    },
                    {
                        'path': 'policies',
                        'name': 'general-insurance-list',
                        'meta': {
                            'title': 'Policies'
                            // 'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/general-insurance/policies'], resolve);
                        }
                    },
                    {
                        'path': 'buy',
                        'name': 'buy-general-insurance',
                        'meta': {
                            'title': 'Buy General Insurance',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/general-insurance/buy'], resolve);
                        }
                    },
                    {
                        'path': 'view/quotation/:id',
                        'name': 'view-general-insurance-quotation',
                        'meta': {
                            'title': 'View Quotation',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/general-insurance/view'], resolve);
                        }
                    },
                    {
                        'path': 'view/:id',
                        'name': 'view-general-insurance',
                        'meta': {
                            'title': 'View Policy',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/general-insurance/policy'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'policies',
                'component': EmptyLayout,
                'meta': {
                    'group': false
                },
                'children': [
                    {
                        'path': '',
                        'name': 'policies',
                        'meta': {
                            'title': 'My Policies',
                            'icon': 'fa-book'
                        },
                        'component': (resolve) => {
                            require(['@/views/policy/list.vue'], resolve);
                        }
                    },
                    {
                        'path': 'view/:id',
                        'name': 'policy-detail',
                        'meta': {
                            'title': 'Policy Details',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/policy/detail.vue'], resolve);
                        }
                    },
                    {
                        'path': 'view-health-insurance-policy/:id',
                        'name': 'health-insurance-policy-detail',
                        'meta': {
                            'title': 'Policy Details',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/policy/health-detail.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'policy-endorsements',
                'component': EmptyLayout,
                'meta': {
                    'group': false
                },
                'children': [
                    {
                        'path': '',
                        'name': 'policy-endorsements',
                        'meta': {
                            'title': 'Policy Claim / Changes',
                            'icon': 'fa-cogs'
                        },
                        'component': (resolve) => {
                            require(['@/views/policy-endorsement/menu.vue'], resolve);
                        }
                    },
                    {
                        'path': 'detail/:id',
                        'name': 'policy-request-detail',
                        'meta': {
                            'title': 'Policy Request Detail',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/policy-endorsement/detail.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'policy-claims',
                'component': EmptyLayout,
                'meta': {
                    'group': false
                },
                'children': [
                    {
                        'path': '',
                        'name': 'policy-claims',
                        'meta': {
                            'title': 'Claims',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/policy-claim/menu.vue'], resolve);
                        }
                    },
                    {
                        'path': 'new/:policyType/:policyId',
                        'name': 'policy-claims-new',
                        'meta': {
                            'title': 'New Policy Claim',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/policy-claim/new.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'policy-changes',
                'component': EmptyLayout,
                'meta': {
                    'group': false
                },
                'children': [
                    {
                        'path': '',
                        'name': 'policy-changes',
                        'meta': {
                            'title': 'Policy Changes',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/policy-change/menu.vue'], resolve);
                        }
                    },
                    {
                        'path': 'new/:policyType/:policyId',
                        'name': 'policy-changes-new',
                        'meta': {
                            'title': 'New Policy Change',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/policy-change/new.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'client-policies',
                'component': EmptyLayout,
                'meta': {
                    'group': false
                },
                'children': [
                    {
                        'path': '',
                        'name': 'client-policies',
                        'meta': {
                            'title': 'Policies',
                            'isAdmin': true,
                            'icon': 'fa-book'
                        },
                        'component': (resolve) => {
                            require(['@/views/policy/admin/list.vue'], resolve);
                        }
                    },
                    {
                        'path': 'view/:id',
                        'name': 'client-policy-detail',
                        'meta': {
                            'title': 'Policy Details',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/policy/admin/detail.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'client-policy-requests',
                'component': EmptyLayout,
                'meta': {
                    'group': false
                },
                'children': [
                    {
                        'path': '',
                        'name': 'client-policy-requests',
                        'meta': {
                            'title': 'Policy Claims / Changes',
                            'isAdmin': true,
                            'icon': 'fa-cogs'
                        },
                        'component': (resolve) => {
                            require(['@/views/policy-endorsement/admin/list.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'client-payments',
                'component': EmptyLayout,
                'meta': {
                    'group': false
                },
                'children': [
                    {
                        'path': '',
                        'name': 'client-payments',
                        'meta': {
                            'title': 'Payments',
                            'isAdmin': true,
                            'icon': 'fa-usd'
                        },
                        'component': (resolve) => {
                            require(['@/views/payment/admin/list.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'client-telematics',
                'component': EmptyLayout,
                'meta': {
                    'group': false
                },
                'children': [
                    {
                        'path': '',
                        'name': 'client-telematics',
                        'meta': {
                            'title': 'Telematics',
                            'isAdmin': true,
                            'icon': 'fa-tablet'
                        },
                        'component': (resolve) => {
                            require(['@/views/telematics/list.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'payment',
                'component': EmptyLayout,
                'meta': {
                    'group': false
                },
                'children': [
                    {
                        'path': '',
                        'name': 'payment-list',
                        'meta': {
                            'title': 'Payments',
                            'icon': 'fa-credit-card'
                        },
                        'component': (resolve) => {
                            require(['@/views/payment/index.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'loyalty-points',
                'component': EmptyLayout,
                'meta': {
                    'group': false
                },
                'children': [
                    {
                        'path': '',
                        'name': 'loyalty-points',
                        'meta': {
                            'title': 'Loyalty Points',
                            'icon': 'fa-star'
                        },
                        'component': (resolve) => {
                            require(['@/views/loyalty-points/index.vue'], resolve);
                        }
                    },
                    {
                        'path': 'transfer',
                        'name': 'loyalty-points-transfer',
                        'meta': {
                            'title': 'Loyalty Points Transfer',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/loyalty-points/transfer.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'shopping-points',
                'component': EmptyLayout,
                'meta': {
                    'group': false
                },
                'children': [
                    {
                        'path': '',
                        'name': 'shopping-points',
                        'meta': {
                            'title': 'Shopping Points',
                            'icon': 'fa-star'
                        },
                        'component': (resolve) => {
                            require(['@/views/shopping-points/index.vue'], resolve);
                        }
                    }
                    // {
                    //     'path': 'transfer',
                    //     'name': 'shopping-points-transfer',
                    //     'meta': {
                    //         'title': 'shopping Points Transfer',
                    //         'isHidden': true
                    //     },
                    //     'component': (resolve) => {
                    //         require(['@/views/loyalty-points/transfer.vue'], resolve);
                    //     }
                    // }
                ]
            },
            // {
            //     'path': 'voucher',
            //     'component': EmptyLayout,
            //     'meta': {
            //         'group': false
            //     },
            //     'children': [
            //         {
            //             'path': '',
            //             'name': 'voucher-list',
            //             'meta': {
            //                 'title': 'Vouchers',
            //                 'icon': 'fa-star'
            //             },
            //             'component': (resolve) => {
            //                 require(['@/views/voucher/list.vue'], resolve);
            //             }
            //         }
            //     ]
            // },
            {
                'path': '/workshops',
                'name': 'workshops',
                'meta': {
                    'title': 'Workshops',
                    'icon': 'fa-wrench',
                    'isPublic': true
                },
                'component': (resolve) => {
                    require(['@/views/workshop/index.vue'], resolve);
                }
            },
            {
                'path': '/roadtax-calculator',
                'name': 'roadtax-calculator',
                'meta': {
                    'title': 'Road Tax Calc.',
                    'icon': 'fa-calculator',
                    'isPublic': true
                },
                'component': (resolve) => {
                    require(['@/views/roadtax-calculator/index.vue'], resolve);
                }
            },
            {
                'path': 'notification/email',
                'component': EmptyLayout,
                'meta': {
                    'group': true,
                    'title': 'Email Notifications',
                    'icon': 'fa-envelope'
                },
                'children': [
                    {
                        'path': '',
                        'name': 'email-list',
                        'meta': {
                            'title': 'Configuration',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/notification/email/index.vue'], resolve);
                        }
                    },
                    {
                        'path': 'queue',
                        'name': 'email-queue-list',
                        'meta': {
                            'title': 'Queue',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/notification/email/queue.vue'], resolve);
                        }
                    },
                    {
                        'path': 'view/',
                        'redirect': { 'name': 'email-list' },
                        'meta': {
                            'isHidden': true
                        }
                    },
                    {
                        'path': 'view/:id',
                        'name': 'email-detail',
                        'meta': {
                            'title': 'View Email Configuration',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/notification/email/detail.vue'], resolve);
                        }
                    },
                    {
                        'path': 'new/',
                        'name': 'new-email',
                        'meta': {
                            'title': 'New Email Configuration',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/notification/email/new.vue'], resolve);
                        }
                    },
                    {
                        'path': 'blast/new',
                        'name': 'blast-email',
                        'meta': {
                            'title': 'New Blast',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/notification/email/blast/new.vue'], resolve);
                        }
                    },
                    {
                        'path': 'blast/',
                        'name': 'blast-email-histories',
                        'meta': {
                            'title': 'Blast Histories',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/notification/email/blast/index.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'notification/sms',
                'component': EmptyLayout,
                'meta': {
                    'group': true,
                    'title': 'SMS Notifications',
                    'icon': 'fa-mobile-phone'
                },
                'children': [
                    {
                        'path': '',
                        'name': 'sms-list',
                        'meta': {
                            'title': 'Configuration',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/notification/sms/index.vue'], resolve);
                        }
                    },
                    {
                        'path': 'queue',
                        'name': 'sms-queue-list',
                        'meta': {
                            'title': 'Queue',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/notification/sms/queue.vue'], resolve);
                        }
                    },
                    {
                        'path': 'view/',
                        'redirect': { 'name': 'sms-list' },
                        'meta': {
                            'isHidden': true
                        }
                    },
                    {
                        'path': 'view/:id',
                        'name': 'sms-detail',
                        'meta': {
                            'title': 'View SMS Configuration',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/notification/sms/detail.vue'], resolve);
                        }
                    },
                    {
                        'path': 'new/',
                        'name': 'new-sms',
                        'meta': {
                            'title': 'New SMS Configuration',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/notification/sms/new.vue'], resolve);
                        }
                    },
                    {
                        'path': 'blast/new',
                        'name': 'blast-sms',
                        'meta': {
                            'title': 'New Blast',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/notification/sms/blast/new.vue'], resolve);
                        }
                    },
                    {
                        'path': 'blast/',
                        'name': 'blast-sms-histories',
                        'meta': {
                            'title': 'Blast Histories',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/notification/sms/blast/index.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'places',
                'component': EmptyLayout,
                'meta': {
                    'group': false
                },
                'children': [
                    {
                        'path': '',
                        'name': 'place-list',
                        'meta': {
                            'title': 'Place Mgmt.',
                            'icon': 'fa-map-marker',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/place/index.vue'], resolve);
                        }
                    },
                    {
                        'path': 'view/',
                        'redirect': { 'name': 'place-list' },
                        'meta': {
                            'isHidden': true
                        }
                    },
                    {
                        'path': 'view/:id',
                        'name': 'place-detail',
                        'meta': {
                            'title': 'View Place',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/place/detail.vue'], resolve);
                        }
                    },
                    {
                        'path': 'add',
                        'name': 'place-add',
                        'meta': {
                            'title': 'Add New Place',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/place/detail.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'telematic-places',
                'component': EmptyLayout,
                'meta': {
                    'group': false
                },
                'children': [
                    {
                        'path': '',
                        'name': 'telematic-place-list',
                        'meta': {
                            'title': 'Telematic Workshop Mgmt.',
                            'icon': 'fa-map-marker',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/place/telematic/index.vue'], resolve);
                        }
                    },
                    {
                        'path': 'view/',
                        'redirect': { 'name': 'telematic-place-list' },
                        'meta': {
                            'isHidden': true
                        }
                    },
                    {
                        'path': 'view/:id',
                        'name': 'telematic-place-detail',
                        'meta': {
                            'title': 'View Telematic Workshop',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/place/telematic/detail.vue'], resolve);
                        }
                    },
                    {
                        'path': 'add',
                        'name': 'telematic-place-add',
                        'meta': {
                            'title': 'Add New Telematic Workshop',
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/place/telematic/detail.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'invite',
                'component': EmptyLayout,
                'meta': {
                    'group': true,
                    'title': 'Invite Friends / Company',
                    'icon': 'fa-share-alt'
                },
                'children': [
                    {
                        'path': '',
                        'name': 'invite',
                        'meta': {
                            'title': 'Invite a friend / company'
                        },
                        'component': (resolve) => {
                            require(['@/views/invite/index.vue'], resolve);
                        }
                    },
                    {
                        'path': 'company',
                        'name': 'invite-by-company',
                        'meta': {
                            'title': 'Bulk Invite'
                        },
                        'component': (resolve) => {
                            require(['@/views/invite/company.vue'], resolve);
                        }
                    },
                    {
                        'path': 'report',
                        'name': 'invite-report',
                        'meta': {
                            'title': 'Invitation Report'
                        },
                        'component': (resolve) => {
                            require(['@/views/invite/report.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'admin-report',
                'component': EmptyLayout,
                'meta': {
                    'group': true,
                    'title': 'Invitation',
                    'icon': 'fa-share-alt'
                },
                'children': [
                    {
                        'path': 'invite',
                        'name': 'admin-invite-by-company',
                        'meta': {
                            'title': 'Bulk Invite on Behalf',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/invite/admin/company.vue'], resolve);
                        }
                    },
                    {
                        'path': 'report',
                        'name': 'admin-invite-report',
                        'meta': {
                            'title': 'Invitation Report',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/invite/admin/report.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'sme',
                'component': EmptyLayout,
                'meta': {
                    'group': false
                },
                'children': [
                    {
                        'path': '',
                        'name': 'sme-list',
                        'meta': {
                            'title': 'SME Report',
                            'icon': 'fa-address-card',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/sme/index.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'reward-report',
                'component': EmptyLayout,
                'meta': {
                    'group': true,
                    'title': 'Rewards',
                    'icon': 'fa-star'
                },
                'children': [
                    {
                        'path': 'earned',
                        'name': 'reward-earning-list',
                        'meta': {
                            'title': 'Rewards Earned',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/loyalty-points/admin/index.vue'], resolve);
                        }
                    },
                    {
                        'path': 'used',
                        'name': 'reward-usage-list',
                        'meta': {
                            'title': 'Rewards Used',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/loyalty-points/admin/usage.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'merchant-manage',
                'component': EmptyLayout,
                'meta': {
                    'group': true,
                    'title': 'Merchants',
                    'icon': 'fa-star'
                },
                'children': [
                    {
                        'path': '',
                        'name': 'merchant-list',
                        'meta': {
                            'title': 'Merchants',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/merchant/list.vue'], resolve);
                        }
                    },
                    {
                        'path': 'add',
                        'name': 'merchant-add',
                        'meta': {
                            'title': 'Add Merchant',
                            'isAdmin': true,
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/merchant/add.vue'], resolve);
                        }
                    },
                    {
                        'path': 'detail',
                        'name': 'merchant-detail',
                        'meta': {
                            'title': 'Merchant',
                            'isAdmin': true,
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/merchant/detail.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'voucher-manage',
                'component': EmptyLayout,
                'meta': {
                    'group': true,
                    'title': 'Vouchers',
                    'icon': 'fa-star'
                },
                'children': [
                    {
                        'path': '',
                        'name': 'voucher-manage-list',
                        'meta': {
                            'title': 'Vouchers',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/voucher/admin/list.vue'], resolve);
                        }
                    },
                    {
                        'path': 'add',
                        'name': 'voucher-manage-add',
                        'meta': {
                            'title': 'Add Voucher',
                            'isAdmin': true,
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/voucher/admin/add.vue'], resolve);
                        }
                    },
                    {
                        'path': 'detail',
                        'name': 'voucher-manage-detail',
                        'meta': {
                            'title': 'Voucher',
                            'isAdmin': true,
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/voucher/admin/detail.vue'], resolve);
                        }
                    },
                    {
                        'path': 'redemption-list',
                        'name': 'voucher-redemption-list',
                        'meta': {
                            'title': 'Redemption List',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/voucher/admin/redemption.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'withdrawal-manage',
                'component': EmptyLayout,
                'meta': {
                    'group': true,
                    'title': 'User Withdrawals',
                    'icon': 'fa-credit-card'
                },
                'children': [
                    {
                        'path': '',
                        'name': 'withdrawal-list',
                        'meta': {
                            'title': 'Withdrawals',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/withdrawal/admin/list.vue'], resolve);
                        }
                    },
                    {
                        'path': 'add',
                        'name': 'withdrawal-add',
                        'meta': {
                            'title': 'Add Withdrawal',
                            'isAdmin': true,
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/withdrawal/admin/add.vue'], resolve);
                        }
                    },
                    {
                        'path': 'detail',
                        'name': 'withdrawal-detail',
                        'meta': {
                            'title': 'Withdrawal',
                            'isAdmin': true,
                            'isHidden': true
                        },
                        'component': (resolve) => {
                            require(['@/views/withdrawal/admin/detail.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'referal-report',
                'component': EmptyLayout,
                'meta': {
                    'group': true,
                    'title': 'Referral Report',
                    'icon': 'fa-bar-chart'
                },
                'children': [
                    {
                        'path': 'registration-report',
                        'name': 'registration-report-admin',
                        'meta': {
                            'title': 'Registration',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/referal-report/admin/registration-report.vue'], resolve);
                        }
                    },
                    {
                        'path': 'quotation-report',
                        'name': 'quotation-report-admin',
                        'meta': {
                            'title': 'Quotation',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/referal-report/admin/quotation-report.vue'], resolve);
                        }
                    },
                    {
                        'path': 'issuance-report',
                        'name': 'issuance-report-admin',
                        'meta': {
                            'title': 'Issuance',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/referal-report/admin/issuance-report.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'affiliate',
                'component': EmptyLayout,
                'meta': {
                    'group': true,
                    'title': 'Affiliate Report',
                    'icon': 'fa-bar-chart'
                },
                'children': [
                    {
                        'path': 'registration-report',
                        'name': 'registration-report',
                        'meta': {
                            'title': 'Registration',
                            'isAffiliate': true
                        },
                        'component': (resolve) => {
                            require(['@/views/referal-report/registration-report.vue'], resolve);
                        }
                    },
                    {
                        'path': 'quotation-report',
                        'name': 'quotation-report',
                        'meta': {
                            'title': 'Quotation',
                            'isAffiliate': true
                        },
                        'component': (resolve) => {
                            require(['@/views/referal-report/quotation-report.vue'], resolve);
                        }
                    },
                    {
                        'path': 'issuance-report',
                        'name': 'issuance-report',
                        'meta': {
                            'title': 'Issuance',
                            'isAffiliate': true
                        },
                        'component': (resolve) => {
                            require(['@/views/referal-report/issuance-report.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'cashout',
                'component': EmptyLayout,
                'meta': {
                    'group': true,
                    'title': 'Cash Payment',
                    'icon': 'fa-credit-card'
                },
                'children': [
                    {
                        'path': 'cash-payment',
                        'name': 'cash-payment',
                        'meta': {
                            'title': 'Records',
                            'isAffiliate': true
                        },
                        'component': (resolve) => {
                            require(['@/views/withdrawal/list.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': 'feedback',
                'name': 'feedback',
                'meta': {
                    'title': 'Feedback',
                    'icon': 'fa-commenting'
                },
                'component': (resolve) => {
                    require(['@/views/feedback/index.vue'], resolve);
                }
            },
            {
                'path': 'impairment',
                'component': EmptyLayout,
                'meta': {
                    'group': false,
                    'isNotForCompany': true
                },
                'children': [
                    {
                        'path': '',
                        'name': 'impairment-list',
                        'meta': {
                            'title': 'Impairments',
                            'icon': 'fa-universal-access',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/impairment/list.vue'], resolve);
                        }
                    },
                    {
                        'path': 'view/:id',
                        'name': 'impairment-detail',
                        'meta': {
                            'title': 'Impairment Detail',
                            'isHidden': true,
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/impairment/detail.vue'], resolve);
                        }
                    },
                    {
                        'path': 'add',
                        'name': 'impairment-add',
                        'meta': {
                            'title': 'Add Impairment',
                            'isHidden': true,
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/impairment/detail.vue'], resolve);
                        }
                    }
                ]
            },
            {
                'path': '/company-above-10-vehicles-attachment',
                'name': 'company-above-10-vehicles-attachment',
                'meta': {
                    'title': 'Company Vehicles Attachment',
                    'isHidden': true
                },
                'component': (resolve) => {
                    require(['@/views/companyFileUpload/companyAbove10VehiclesAttachment.vue'], resolve);
                }
            },
            {
                'path': 'partner-product-report',
                'component': EmptyLayout,
                'meta': {
                    'group': false,
                    'isNotForCompany': true
                },
                'children': [
                    {
                        'path': '',
                        'name': 'partner-product-report',
                        'meta': {
                            'title': 'Partner Product Report',
                            'icon': 'fa-handshake-o',
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/partner-product-report/list.vue'], resolve);
                        }
                    },
                    {
                        'path': 'detail',
                        'name': 'partner-product-report-detail',
                        'meta': {
                            'title': 'Partner Product Detailed Report',
                            'isHidden': true,
                            'isAdmin': true
                        },
                        'component': (resolve) => {
                            require(['@/views/partner-product-report/detail.vue'], resolve);
                        }
                    }
                ]
            }
        ]
    }
];
